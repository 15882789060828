import React, {ReactNode, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useMsal} from '@azure/msal-react'
import axios from 'axios'
import {signinRequestApi, signinRequestFactory} from '../../authentication/authConfig'
import {SilentRequest} from '@azure/msal-browser'

const RequestInterceptor = ({children}: { children?: ReactNode }) => {
    const {instance} = useMsal()
    const navigate = useNavigate()


    const getTokenFor = (silentRequest: SilentRequest) => {
        return instance
            .acquireTokenSilent(silentRequest)
            .catch((ex) => {
                console.error(ex)
                const e2eAccessToken = window.sessionStorage.getItem('e2e-access-token')

                if (!e2eAccessToken) {
                    console.error(
                        'Not able to get the access token for this active account'
                    )
                    navigate('/login')
                } else {
                    const accessToken = JSON.parse(e2eAccessToken || '')

                    return {accessToken}
                }
            })
    }

    useEffect(() => {
        const interceptor = axios.interceptors.request.use(async (config) => {
            let response = null
            if (config?.url?.includes(process.env.REACT_APP_FACTORY_API_URL ?? ''))
                response = await getTokenFor(signinRequestFactory)
            else response = await getTokenFor(signinRequestApi)

            if (!response?.accessToken) {
                console.error('No access token available')
                navigate('/login')
            }

            const bearer = `Bearer ${response?.accessToken}`
            if (config.headers) {
                config.headers.Authorization = bearer
                config.headers['Content-Type'] = 'application/json'
            }
            return config
        })
        return () => {
            axios.interceptors.request.eject(interceptor)
        }
    }, [])

    return <>{children}</>
}
export {RequestInterceptor}
