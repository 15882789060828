import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {resetRedirectToLogin, useTryToGetToken} from './AuthHooksAndFunctions'
import {signinRequestApi} from './authConfig'
import {useMsal} from '@azure/msal-react'
import LoadingSpinner from '../genericComponents/spinner/LoadingSpinner'

const AutoSignInPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const {instance} = useMsal()
    // signinRequest.loginHint = LocalStorage.getUserEmail() ?? ''

    useTryToGetToken(
        () => navigate(signinRequestApi.redirectStartPage ?? '/'),
        () => setLoading(false)
    )

    if (loading) {
        return <LoadingSpinner/>
    }

    const startSignIn = () => {
        signinRequestApi.redirectStartPage = signinRequestApi.redirectStartPage ?? '/'
        instance
            .loginRedirect(signinRequestApi)
            .then((_) => {
                resetRedirectToLogin()
            })
    }

    if (window.sessionStorage.getItem('e2e')) {
        return <button id="e2e-login" type="button" onClick={startSignIn}>Login</button>
    }


    startSignIn()
    return <LoadingSpinner/>
}

export default AutoSignInPage
