import {Configuration, RedirectRequest} from '@azure/msal-browser'

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_oauth_clientId ?? '',
        authority: `https://${process.env.REACT_APP_oauth_tenant_url}/${process.env.REACT_APP_oauth_tenant}/${process.env.REACT_APP_oauth_b2c_flow_signin}`,
        knownAuthorities: [
            process.env.REACT_APP_oauth_tenant_url ?? ''
        ], // array of URIs that are known to be valid
        redirectUri: '/',
        postLogoutRedirectUri: '/'
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to 'true' if you are having issues on IE11 or Edge
    }
}

export type CustomSilentRequest = {redirectStartPage?: string} & RedirectRequest

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const signinRequestApi: CustomSilentRequest = {
    prompt: 'login',
    scopes: [process.env.REACT_APP_oauth_scope_admin_api ?? '', 'openid', 'offline_access']
}

export const signinRequestFactory: CustomSilentRequest = {
    prompt: 'login',
    scopes: [process.env.REACT_APP_oauth_scope_factory_api ?? '',
        'openid', 'offline_access']
}