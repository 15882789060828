import {GridValueGetterParams} from '@mui/x-data-grid'
import React from 'react'
import {FactoryUser} from '../../factoryUser/CreateFactoryUser/model/FactoryUser'
import {formatDate, formatMilliseconds, formatMonth} from '../utils/DateService'
import {getUserTypeDisplayName, UserRoleEnum} from '../../factoryUser/CreateFactoryUser/model/UserRoleEnum'
import {GridCellParams} from '@mui/x-data-grid/models/params/gridCellParams'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {IconButton} from '@mui/material'
import '../../factoryUser/ActiveFactoryUsersTable/ActiveFactoryUsersTable.scss'


export const getRoleLabel = (params: GridValueGetterParams): string => {
    if (!params.row?.roles || params.row.roles.length === 0) {
        return '-'
    }
    const role = params.row.roles[0] as keyof typeof UserRoleEnum
    const displayName = getUserTypeDisplayName[UserRoleEnum[role]]
    return displayName ?? '-'
}


export const formatRole = ({row}: { row: { roles: UserRoleEnum } }) => {
    const role = row.roles[0] as keyof typeof UserRoleEnum
    const displayName = getUserTypeDisplayName[UserRoleEnum[role]]
    return formatString(displayName ?? '-')
}

const formatString = (label: string) => {
    if (!label) {
        return '-'
    }
    return <span title={label} className="label-format">{label}</span>
}

export const formatEmail = ({row}: { row: FactoryUser }) => {
    return formatString(row?.email)
}

export const getRequesterEmail = (params: GridValueGetterParams) => {
    return params.row?.email
}
export const getFormattedDate = (date: GridValueGetterParams) => {
    const formattedDate = [...date.value]
    const dateToDisplay = formatMilliseconds(formattedDate)
    formatMonth(dateToDisplay)
    if (formattedDate) {
        return formatDate(formattedDate)
    }
}

export const updateRole = (params: GridCellParams, onClick: (params: GridCellParams) => void): React.ReactNode => {
    return (
        <span className="role-label-format">
            {formatRole({row: params.row})}
            <IconButton onClick={() => onClick(params)} color="primary" aria-label="edit"
                        className="icon-clickable"
                        data-testid={`edit-role-button-${params.row.email}`}
            >
                    <EditOutlinedIcon className="role-edit-icon"/>
            </IconButton>
        </span>


    )
}

